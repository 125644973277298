export class Configurations {
    id: number;
    admin_footer: string;
    p_iva: string;
    address: string;
    website: string;
    pdf_logo: string;
    iva: string;
    created_at: Date;
}
