export class System {
    id: number;
    name: string;
    image: string;
    motion_id: string;
    motor_id: string;
    chain_id: string;
    telecomand_id: string;
    updated_at: string;
    created_at: string;
}
