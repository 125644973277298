export class Product {
    id: number;
    curtain_id: number;
    curtain: string;
    curtainColor: string;
    desc: string;
    curtain_color_id: number;
    system_id: number;
    system: string;
    systemColor: string;
    reference: string;
    systemImage: string;
    system_color_id: number;
    price: string;
    priceType: number;
    totalPrice: number;
    width: string;
    height: string;
    motion_id: number;
    motor_id: number;
    motor: string;
    motor_image: string;
    chain_id: number;
    chain: string;
    chain_image: string;
    telecomand_id: number;
    telecomand: string;
    telecomand_image: string;
    quantity: string;
    state: number;
    cart_id: number;
    iva_shipping: number;
    iva_home_service: number;
    updated_at: string;
    created_at: string;
    preventive: number;
    markup: number;
    productId: string;
    shownPrice: number;
}
