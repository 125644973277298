export class TypeMotor {
    id: number;
    name: string;
    image: string;
    price: string;
    description: string;
    motion_id: number;
    telecomand_id : number;
    updated_at: string;
    created_at: string;
}
