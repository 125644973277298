﻿export class User {
    id: number;
    name: string;
    email: string;
    email_verified_at: string;
    password: string;
    remember_token: string;
    created_at: string;
    updated_at: string;
    token: string;
    role: string;
    iva: number;
    iva_shipping: number;
    iva_home_service: number;
    superAdmin: number;
    shipping: number;
    commision: number;
    shop: number;
    phone_number: string;
    zone_covered: string;
    address: string;
    image: string;
    description: string;
    unpaidCommision: number;
}
