export class Preventive {
    id: number;
    date: Date;
    status: string;
    reference: string;
    shop: number;
    client: number;
    user_created: number;
    discount: number;
    shopDiscount: number;
    pdf: string;
    amount: number;
    commision: number;
    shipping: number;
    service_on_home: number;
    iva_home_service: number;
    iva_shipping: number;
    iva: number;
    invoiceNo: number;
    totalAmount: number;
    assignedId: number;
    discountId: number;
    markup: number;
    assignedType: string;
    p_iva: string;
    invoiced: boolean;
}
