import { Component, OnInit, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import { FormBuilder, FormGroup } from '@angular/forms';
import { LoaderInterceptor } from '../_helpers/loader';

@Component({
	selector: 'app-confirm-dialog',
	templateUrl: './confirm-dialog.component.html',
	styleUrls: ['./confirm-dialog.component.scss']
})

export class ConfirmDialogComponent{

	constructor(
		public LoaderInterceptor: LoaderInterceptor,
		private dialogRef: MatDialogRef<ConfirmDialogComponent>
		) {
	}

	ngOnInit() {

	}

	delete() {
		this.LoaderInterceptor.setLoader = true;
		this.dialogRef.close(true);
	}

	close() {
		this.dialogRef.close(false);
	}
}
