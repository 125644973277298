import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-motor-componentt-add',
  templateUrl: './motor-componentt-add.component.html',
  styleUrls: ['./motor-componentt-add.component.scss']
})
export class MotorComponenttAddComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
