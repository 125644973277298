export * from './alert.service';
export * from './authentication.service';
export * from './user.service';
// export * from './system.service';
export * from './system-color.service';
export * from './product.service';
export {CurtainsService} from './curtains.service';
export * from './curtain-color.service';
export * from './type-motion.service';
export * from './type-motor.service';
export * from './type-chain.service';
export * from './type-telecomand.service';
export * from './dimensions.service';
export * from './preventives.service';
export * from './profile.service';
export * from './clients.service';
export * from './orders.service';
export * from './previous-route.service';
export {SystemService} from './system.service';