import { Component, OnInit, ElementRef } from '@angular/core';
import { ROUTES } from '../sidebar/sidebar.component';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { Router } from '@angular/router';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
    private listTitles: any[];
    location: Location;
    mobile_menu_visible: any = 0;
    private toggleButton: any;
    private sidebarVisible: boolean;

    constructor(location: Location, private element: ElementRef, private router: Router) {
        this.location = location;
        this.sidebarVisible = false;
    }

    ngOnInit() {
        this.listTitles = ROUTES.filter(listTitle => listTitle);
        const navbar: HTMLElement = this.element.nativeElement;
        this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
        this.router.events.subscribe((event) => {
            this.sidebarClose();
            var $layer: any = document.getElementsByClassName('close-layer')[0];
            if ($layer) {
                $layer.remove();
                this.mobile_menu_visible = 0;
            }
        });
    }

    sidebarOpen() {
        const toggleButton = this.toggleButton;
        const body = document.getElementsByTagName('body')[0];
        setTimeout(function () {
            toggleButton.classList.add('toggled');
        }, 500);

        body.classList.add('nav-open');

        this.sidebarVisible = true;
    };

    sidebarClose() {
        const body = document.getElementsByTagName('body')[0];
        this.toggleButton.classList.remove('toggled');
        this.sidebarVisible = false;
        body.classList.remove('nav-open');
    };

    sidebarToggle() {
        // const toggleButton = this.toggleButton;
        // const body = document.getElementsByTagName('body')[0];
        var $toggle = document.getElementsByClassName('navbar-toggler')[0];

        if (this.sidebarVisible === false) {
            this.sidebarOpen();
        } else {
            this.sidebarClose();
        }
        const body = document.getElementsByTagName('body')[0];

        if (this.mobile_menu_visible == 1) {
            // $('html').removeClass('nav-open');
            body.classList.remove('nav-open');
            if ($layer) {
                $layer.remove();
            }
            setTimeout(function () {
                $toggle.classList.remove('toggled');
            }, 400);

            this.mobile_menu_visible = 0;
        } else {
            setTimeout(function () {
                $toggle.classList.add('toggled');
            }, 430);

            var $layer = document.createElement('div');
            $layer.setAttribute('class', 'close-layer');


            if (body.querySelectorAll('.main-panel')) {
                document.getElementsByClassName('main-panel')[0].appendChild($layer);
            } else if (body.classList.contains('off-canvas-sidebar')) {
                document.getElementsByClassName('wrapper-full-page')[0].appendChild($layer);
            }

            setTimeout(function () {
                $layer.classList.add('visible');
            }, 100);

            $layer.onclick = function () { //asign a function
                body.classList.remove('nav-open');
                this.mobile_menu_visible = 0;
                $layer.classList.remove('visible');
                setTimeout(function () {
                    $layer.remove();
                    $toggle.classList.remove('toggled');
                }, 400);
            }.bind(this);

            body.classList.add('nav-open');
            this.mobile_menu_visible = 1;
        }
    };

    getTitle() {
        var titlee = this.location.prepareExternalUrl(this.location.path());
        if (titlee.charAt(0) === '#') {
            titlee = titlee.slice(2);
        }
        titlee = titlee.split('/').pop();

        for (var item = 0; item < this.listTitles.length; item++) {
            if (this.listTitles[item].path === titlee) {
                return this.listTitles[item].title;
            }
        }
        return 'Dashboard';
    }

    public setTitle() {
        let title = "";
        let text = this.location.path();
        if(this.location.path().replace(/[^/]/g, "").length === 3)
            text = this.location.path().substring(0, this.location.path().lastIndexOf("/"));

        switch (text) {

            case "/admin/dashboard":
                title = "Cruscotto";
                break;

            case "/admin/profile":
                title = "Profilo";
                break;

            case "/admin/system":
                title = "Sistemi";
                break;

            case "/admin/shops":
                title = "Negozi";
                break;

            case "/admin/agent":
                title = "Agenti";
                break;

            case "/admin/agent-matrices":
                title = "Matrici Di Agenti";
                break;

            case "/admin/users":
                title = "Admin";
                break;

            case "/admin/preventive":
                title = "Preventivi";
                break;

            case "/admin/orders":
                title = "Ordini";
                break;

            case "/admin/invoices":
                title = "Conferma d’ordine";
                break;

            case "/admin/admin-invoices":
                title = "Conferma d’ordine";
                break;

            case "/admin/clients":
                title = "Clienti";
                break;

            case "/admin/curtain":
                title = "Tessuti";
                break;

            case "/admin/color_system":
                title = "Colori Dei Sistemi";
                break;

            case "/admin/color_curtain":
                title = "Colori Dei Tessuti";
                break;

            case "/admin/motori":
                title = "Motori";
                break;

            case "/admin/chains":
                title = "Catene";
                break;
            case "/admin/telecomands":
                title = "Telecomandi";
                break;
            case "/admin/curtain-colors":
                title = "Colori dei tessuti";
                break;
            case "/admin/system-colors":
                title = "Colori dei sistema";
                break;
            case "/admin/updateCurtain":
                title = "Tessuti";
                break;
            case "/admin/newCurtain":
                title = "Tessuti";
                break;

            default:
                title = "Lista dei prodotti";
                break;
        }

        return title;
    }

}
